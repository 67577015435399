<template>
  <div class="my_div">
    <div class="my_ln">
        <div class="my_ln_1" v-if="!isMobile">
            <div class="button">
                <router-link class="router" :to="{path:'/index'}">
                <i class="el-icon-arrow-left"></i>主页
                </router-link>
            </div>
        </div>
        <div class="right">
        <div class="my_ln_2">
            <!-- <div class="top">个人信息</div> -->
            <div class="bottom">
                <div class="box_1">
                    <div class="text1">我的账号：{{account.username}}</div>
                    <div class="text2" @click="dialogA=true">修改密码</div>
                </div>
                <div class="box_2">
                    <div class="box">
                        <div class="label">我的套餐：</div>
                        <div class="text ceng">{{account.account_type}}</div>
                    </div>
                    <div class="box">
                        <div class="label">到期时间：</div>
                        <div class="text">{{account.expire_date==-1?"-":account.expire_date}}</div>
                    </div>
                </div>
                <div class="box_2">
                    <div class="box">
                        <div class="label">剩余次数：</div>
                        <div class="text">{{account.remain_requests==-1?'无限':account.remain_requests}}次</div>
                        
                    </div>
                    <div class="box">
                        <div class="label">积分：</div>
                        <div class="text">{{account.points}}<el-tooltip placement="bottom">
                                <div slot="content" class="prompt">积分可用于兑换使用次数和时长，敬请期待
                                </div>
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip></div>
                    </div>
                </div>
                <div class="box_2">
                    <div class="box">
                        <div class="label">连续多轮对话：  </div>
                        <div class="text">{{account.multi_turn==0?'不支持':"支持"}}
                            <el-tooltip placement="bottom">
                                <div slot="content" class="prompt">在多轮对话状态下，AI会记忆历史对话，因此用户可以针对上一轮AI的回复展开追问。例如可以追问“继续写”、“总结下刚才的对话”、“把刚才你说的内容转成英文”等。
                                    <span class="multichatexample" @click="dialogexample=true">点击查看多轮对话示例</span>
                                </div>
                                <i class="el-icon-warning-outline"></i>
                            </el-tooltip>
                        </div>
                        
                    </div>
                    <div class="box">
                        <div class="label">回复长度限制：</div>
                        <div class="text">{{account.maxlen==-1?'无限制':account.maxlen+"字"}}</div>
                    </div>
                </div>
                <div class="box_2">
                    <div class="box" v-if="account.human_support!=0">
                        <div class="label hui">有问题？</div>
                        <div class="text ceng" style="cursor: pointer;" @click="dialogD=true">联系专属客服</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sjtc" v-if="isMobile">上滑选择更多套餐</div>
        <div class="my_ln_3" v-if="isMobile">
            <div class="box" :class="item.title=='免费试用'?'mianfei':''" v-if="item.show&&item.title!='免费试用'" v-for="(item,index) in alipayBuytypes" :key="index">
                <div class="title">套餐{{chineseText(index)}}</div>
                <div class="box_1">{{item.title}}</div>
                <div class="box_2">￥{{item.price}}<span class="span">{{item.fake_price}}元</span></div>
                <div class="box_3"></div>
                <div class="box_4"><i class="icon" :class="item.num_requests!=-1?'el-icon-error':'el-icon-success'"></i>次数限制：{{item.num_requests==-1?'无限':item.num_requests}}次</div>
                <div class="box_4"><i class="icon" :class="item.maxlen!=-1?'el-icon-error':'el-icon-success'"></i>回复长度限制：{{item.maxlen==-1?'无限制':item.maxlen+"字"}}
                   <!-- <i class="el-icon-warning-outline" v-if="item.maxlen==-1" title="开通超级vip套餐后，本站不会限制AI回复长度，但OpenAI官方仍有限制，输入和输出分别有约1000汉字或2000英文单词的限制"></i> -->
                    <el-tooltip placement="bottom">
                        <div slot="content" class="prompt">开通vip套餐后，本站不会限制AI回复长度，但注意AI的能力是有限的，经验上AI很少会写超过1000字的内容，且输入太长的时候会表现不佳</div>
                       
                        <i class="el-icon-warning-outline" v-if="item.maxlen==-1"></i>
                    </el-tooltip>
                </div>
                <div class="box_4"><i class="icon" :class="item.multi_turn==0?'el-icon-error':'el-icon-success'"></i>连续多轮对话：{{item.multi_turn==0?'不支持':'支持'}}
                     <el-tooltip placement="bottom">
                        <div slot="content" class="prompt">在多轮对话状态下，AI会记忆历史对话，因此用户可以针对上一轮AI的回复展开追问。例如可以追问“继续写”、“总结下刚才的对话”、“把刚才你说的内容转成英文”等。
                            <span class="multichatexample" @click="dialogexample=true">点击查看多轮对话示例</span></div>
                            
                        <i class="el-icon-warning-outline" v-if="item.maxlen==-1"></i>
                    </el-tooltip>
                </div>
                <div class="box_4"><i class="icon" :class="item.human_support==0?'el-icon-error':'el-icon-success'"></i>{{item.human_support==0?'无客服':'专属VIP客服'}}</div>
                <div class="box_5" v-if="item.title!='免费试用'" @click="getAlipayPay(index)">立即升级</div>
            </div>
        </div>
        <div class="my_ln_3" id="swiper_scroll_cusor" v-else>
            <div class="ms">左右滑动查看更多</div>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="box swiper-slide" :class="item.title=='免费试用'?'mianfei':''" v-if="item.show" v-for="(item,index) in alipayBuytypes" :key="index">
                        <div class="title" v-if="item.title!='免费试用'">套餐{{chineseText(index)}}</div>
                        <div class="box_1">{{item.title}}</div>
                        <div class="box_2">￥{{item.price}}<span class="span">{{item.fake_price}}元</span></div>
                        <div class="box_3"></div>
                        <div class="box_4"><i class="icon" :class="item.num_requests!=-1?'el-icon-error':'el-icon-success'"></i>次数限制：{{item.num_requests==-1?'无限':item.num_requests}}次</div>
                        <div class="box_4"><i class="icon" :class="item.maxlen!=-1?'el-icon-error':'el-icon-success'"></i>回复长度限制：{{item.maxlen==-1?'无限制':item.maxlen+"字"}}
                        <!-- <i class="el-icon-warning-outline" v-if="item.maxlen==-1" title=""></i> -->
                            <el-tooltip placement="bottom">
                                <div slot="content" class="prompt">开通vip套餐后，本站不会限制AI回复长度，但注意AI的能力是有限的，经验上AI很少会写超过1000字的内容，且输入太长的时候会表现不佳</div>
                            
                                <i class="el-icon-warning-outline" v-if="item.maxlen==-1"></i>
                            </el-tooltip>
                        </div>
                        <div class="box_4"><i class="icon" :class="item.multi_turn==0?'el-icon-error':'el-icon-success'"></i>连续多轮对话：{{item.multi_turn==0?'不支持':'支持'}}
                             <el-tooltip placement="bottom">
                                <div slot="content" class="prompt">在多轮对话状态下，AI会记忆历史对话，因此用户可以针对上一轮AI的回复展开追问。例如可以追问“继续写”、“总结下刚才的对话”、“把刚才你说的内容转成英文”等。
                                    <span class="multichatexample" @click="dialogexample=true">点击查看多轮对话示例</span></div>
                                    <!-- <el-button type="text" @click="dialogexample = true"><span>点击查看多轮对话示例</span></el-button> -->
                                <i class="el-icon-warning-outline" v-if="item.maxlen==-1"></i>
                            </el-tooltip>
                        
                        </div>
                        <div class="box_4"><i class="icon" :class="item.human_support==0?'el-icon-error':'el-icon-success'"></i>{{item.human_support==0?'无客服':'专属VIP客服'}}</div>
                        <div class="box_5" v-if="item.title!='免费试用'" @click="getAlipayPay(index)">立即升级</div>
                    </div>
                </div>
                <!-- Add Pagination -->
                <!-- <div class="swiper-scrollbar"></div> -->
                
            </div>
            <!-- <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div> -->
        </div>

        </div>
    </div>
    <el-dialog :close-on-click-modal="false" title="修改密码" :width="isMobile?'90%':'395px'" :visible.sync="dialogA">
        <div class="dialog_box">
            <input type="text" class="input-text" v-model="passwordParam.password" placeholder="输入旧密码" >
            <input type="text" class="input-text" v-model="passwordParam.new_password" placeholder="输入新密码" >
            <input type="text" class="input-text" v-model="passwordParam.new_password2" placeholder="再次输入新密码" >
            <div class="button" @click="updateOauthChangepassword()">确认</div>
        </div>
    </el-dialog>

      <el-dialog :close-on-click-modal="true" title="多轮对话示例" :width="isMobile?'90%':'700px'" :visible.sync="dialogexample">
        <div class="demo-image__lazy" v-if="!isMobile">
            
            <el-image  v-for="url in urls" :key="url" :src="url" lazy></el-image>
        </div>
        <div class="demo-image__lazy" v-else>
            <el-image v-for="mobile_url in mobile_urls" :key="mobile_url" :src="mobile_url" lazy ></el-image>
        </div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="服务购买协议" :width="isMobile?'90%':'596px'" :visible.sync="dialogB">
        <div class="dialog_box">
            <div class="overflowAuto">
                <div class="ms" v-if="dialogB">
                    您将付款{{alipayBuytypes[indexPay].price}}元，购买{{alipayBuytypes[indexPay].title}}。在购买前，请您务必仔细阅读并理解本协议。请您知悉，如果您选择继续访问本网站、或使用本网站提供的本服务以及通过各类方式利用本网站的行为（以下统称“本服务”），则视为接受并同意本声明全部内容。
                </div>
                <div class="ms">        
                    <strong style="font-weight: bold !important;">1. VIP服务为虚拟商品，售出后不支持退款。</strong><br>
                    2. 账号仅支持单人使用，请勿分享给他人，否则可能被封号。<br>
                    <strong style="color: red !important; font-weight: bold !important;">3. 严禁脚本调用，风控系统检测到会自动封号，不仅无法解封无法退款，且我们会以破坏计算机系统罪提起诉讼，要求索赔。</strong><br>
                    4. 严禁使用脚本注册账号，风控系统检测到后会进行批量封号并限制IP访问本站。<br>
                    5. 请勿查询政治敏感、违反国家法律法规的内容，否则可能被封号，甚至承担法律责任。<br>
                    6.您确认使用本服务时输入的内容将不包含您的个人信息。您同意并承诺，在使用本服务时，不会披露任何保密、敏感或个人信息。<br>
                    7.您确认并知悉本服务生成的所有内容都是由人工智能模型生成，所以可能会出现意外和错误的情况，请确保检查事实。我们对其生成内容的准确性、完整性和功能性不做任何保证，并且其生成的内容不代表我们的态度或观点，仅为提供更多信息，也不构成任何建议或承诺。对于您根据本服务提供的信息所做出的一切行为，除非另有明确的书面承诺文件，否则我们不承担任何形式的责任。<br>
                    8.不论在何种情况下，本网站均不对由于网络连接故障，电力故障，火灾，洪水，风暴，爆炸，黑客攻击，互联网病毒等不可抗力，网络运营商技术调整，网信办等政府单位的临时管制或任何其他不能合理控制的原因而造成的本服务不能访问、服务中断、信息及数据的延误、停滞或错误，不能提供或延迟提供服务而承担责任。<br>
                    9.当本服务以链接形式推荐其他网站内容时，我们并不对这些网站或资源的可用性负责，且不保证从这些网站获取的任何内容、产品、服务或其他材料的真实性、合法性。在法律允许的范围内，本网站不承担您就使用本服务所提供的信息或任何链接所引致的任何直接、间接、附带、从属、特殊、继发、惩罚性或惩戒性的损害赔偿。<br>
                </div>
            </div>
            <div class="zf_box">
                <el-checkbox v-model="checked">我已阅读并同意该协议</el-checkbox>
                <div class="button" @click="getAlipayPay()">支付</div>
            </div>
        </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="请确认支付状态" :width="isMobile?'90%':'402px'" :visible.sync="dialogC">
        <div class="dialog_box">
            <div class="ms">
                请在弹出的页面完成付款，如未弹出或支付遇到问题，请点击“遇到问题”进行反馈。
            </div>
            <div class="wczf_box">
                <div class="button1" @click="dialogC=false;dialogD=true">遇到问题？</div>
                <div class="button" @click="getpaycheck()">完成支付</div>
            </div>
        </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="扫码添加专属客服微信" :width="isMobile?'90%':'402px'" :visible.sync="dialogD">
        <div class="dialog_box">
            <img src="@/assets/images/new/kf.jpg" class="kf_img" />
        </div>
    </el-dialog>
  </div>

  
</template>

<script>
import Swiper from 'swiper';
import { payAccountcheck,oauthChangepassword,alipayBuytypes,alipayPay,paycheck} from "@/api/user";
export default {
  data() {
   
    return {
        dialogA:false,
        dialogB:false,
        dialogC:false,
        dialogD:false,
        dialogexample:false,
        checked:true,

         urls: [
            require("@/assets/images/example1.png"),
            require("@/assets/images/example2.png"),
            require("@/assets/images/example3.png"),
            require("@/assets/images/example4.png"),
        
        ],
        mobile_urls: [
            require("@/assets/images/me1.jpg"),
            require("@/assets/images/me2.jpg"),
            require("@/assets/images/me3.jpg"),
            require("@/assets/images/me4.jpg"),

        ],

        account:{},
        passwordParam:{
            "password":"",
            "new_password":"",
            "new_password2":"",
        },
        indexPay:0,
        alipayBuytypes:[],

        isMobile:"",
    };
  },
  mounted() {
    var that = this;
    that.isMobile=navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    that.getPayAccountcheck();
    that.getAlipayBuytypes();
    if(that.$route.query.pay==1){
        that.dialogC=true;
    }
  },
  methods: {
    // 数字转换
    chineseText(index) {
      let chinese = ['一', '二', '三', '四', '五', '六', '七', '八', '九']
      return chinese[index]
    },
    //支付确认
    getpaycheck(){
        paycheck().then((res) => {
            var that = this;
            if(res.code==0){
                that.$message.success("支付成功");
                that.dialogC=false;
                that.$router.push({
                    path: "/my?s=1",
                });
            }
        });
    },
    //支付
    getAlipayPay(index){
        var that = this;
        if(that.dialogB){
            if(that.checked ){
                var param={
                    "account_type":that.indexPay,
                    "is_wise":that.isMobile?1:0,
                }
                alipayPay(param).then((res) => {
                    if(res.code==0){
                        that.dialogB=false;
                        window.open(res.result.pay_url,"_self")
                    }
                });
            }
        }else{
            that.dialogB=true;
            that.indexPay=index;
        }
        
    },
    //立即升级列表数据
    getAlipayBuytypes(){
        var that = this;
        alipayBuytypes().then((res) => {
            if(res.code==0){
                that.alipayBuytypes=res.result.buy_types;
                if(!that.isMobile){
                    that.$nextTick(function(){
                        var swiper = new Swiper('.swiper-container', {
                            scrollbar: '.swiper-scrollbar',
                            nextButton: '.swiper-button-next',
                            prevButton: '.swiper-button-prev',
                            scrollbarHide: true,
                            slidesPerView: 'auto',
                            // centeredSlides: true,
                            spaceBetween: '3%',
                            // initialSlide:1,
                            mousewheelControl:true,
                            mousewheel: {
                            releaseOnEdges: true, //当Swiper处于边缘位置时（第一个或最后一个slide），Swiper释放鼠标滚轮事件，鼠标可以控制页面滚动,即页面正常向上或者向下滚动。
                                eventsTarged: '#swiper_scroll_cusor', // 鼠标在这个区域都可以控制伦比哦，不设置就只能是鼠标在1200区域内才能控制轮播
                            },
                            grabCursor: true
                        });
                    })
                }
            }
        });
    },
    //修改密码
    updateOauthChangepassword(){
        var that = this;
        if(!that.passwordParam.password){
            that.$message.error("旧密码不能为空");
            return false;
        }
        if(!that.passwordParam.new_password){
            that.$message.error("新密码不能为空");
            return false;
        }
        if(that.passwordParam.new_password!=that.passwordParam.new_password2){
            that.$message.error("新密码2个不相同");
            return false;
        }
        var param={
            "password":that.passwordParam.password,
            "new_password":that.passwordParam.new_password,
        }
        oauthChangepassword(param).then((res) => {
            if(res.code==0){
                that.$message.success(res.result.message);
                that.dialogA=false;
            }
        });
    },
    //获取用户信息
   getPayAccountcheck(){
        var that = this;
        payAccountcheck().then((res) => {
            if(res.code==0){
                that.account=res.result;
            }
        });
    },
  },
};
</script>
<style lang="less" scoped>
  
    .prompt {
  // 必须设置宽度
  width: 270px;
  word-break: break-word;
  white-space: pre-line;
  clear: both;
    // text-align: center;
    }
    .multichatexample{
        color: #409EFF;
        text-decoration: underline;
        cursor: pointer;
    }
      .swiper-container {
        width: 100%;
        padding-right: 5%;
        // overflow: initial;
    }
    .el-image{
        display: block;
        margin-bottom: 0;
    }
    </style> 
</style>
